import './auth.css';
import {
  faApple,
  faGoogle,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import {
  validateEmail
} from "../../../../utils";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import FrontendConfig from '../../../../assets/js/config';
import useAPIError from "../../../../commons/hooks/useAPIError";
import { Context } from "../../../../context/auth/Context";
import { DataContext } from "../../../../context/data/DataContext";
import { dataTypes, types } from '../../../../context/types';
import {
  fetchLogin,
  fetchLoginMFA,
  fetchLoginWebauthn,
} from "../../../../services/auth";
import {
  b64decode,
  b64encode,
  challenge_from_verifier,
  generateRandomString,
} from "../../../../services/common";
import {
  fetchResendVerifyAccount,
  fetchUserProfile,
  fetchDomainData,
} from "../../../../services/services";
import InputPIN from '../../../../views/components/InputPIN';
import { MobileSSOButtons } from "../../../../views/components/MobileLogin/MobileSSOButtons";
import SSOButton from "../../../../views/components/SSOButton";
import wwpass from "../../../../assets/img/wwpass.svg";

const Login = () => {
  const history = useHistory();

  /**
   * If the login page has been opened from the mobile app we need to add a localstorage flag.
   */
  const isMobile = localStorage.getItem("isMobile") || window.location.search.includes("isMobile") ? true : false;

  // Notify
  const { addNotify } = useAPIError();
  // Login
  const { dispatch } = useContext(Context);
  const { dispatch2 } = useContext(DataContext);

  const [state, setState] = useState({});
  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const handleNewAccount = (e) => {
    e.preventDefault();
    history.push("/auth/register");
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setRetry(false);
    setLoggin({ isLogged: login.isLogged, message: null });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    history.push("/auth/forgot-password");
  };

  const [dataForm, setDataForm] = useState({
    email: "",
    password: "",
  });

  const validateInput = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case "email":
        setDataForm((prevForm) => ({
          ...prevForm,
          email: value,
          emailInvalid: validateEmail(value),
        }));
        break;
      case "password":
        setDataForm((prevForm) => ({
          ...prevForm,
          password: value,
        }));
        break;
      default:
        break;
    }
  };

  const [emailChecked, setEmailChecked] = useState(false);
  const [retry, setRetry] = useState(false);
  const [requestMFA, setRequestMFA] = useState({
    request: false,
    message: null,
    friendlyDevice: null,
    session: null,
  });
  const [login, setLoggin] = useState({ isLogged: false, message: null });
  const [loading, setLoading] = useState(false);
  const [loadingWebauthn, setLoadingWebauthn] = useState(false);

  const [error, setError] = useState({ email: false, password: false });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if(!emailChecked){
      await handleSubmitEmailNotChecked();
      setLoading(false);
    }else{
      await handleSubmitEmailChecked(e);
      setLoading(false);
    }
  };

  const handleSubmitEmailChecked = async (e) => {
    if (login && (login.message === "User is not confirmed." || retry)) {
      setRetry(true);
      const res = await fetchResendVerifyAccount({ email: dataForm.email });

      if (res.status === "Success") {
        setLoggin((prevLogin) => ({
          ...prevLogin,
          message: res.message,
          status: true,
        }));
      } else if (res.errorType === "LimitExceededException") {
        setLoggin((prevLogin) => ({
          ...prevLogin,
          message: res.errorMessage,
        }));
      } else if (res.status === "Forbidden") {
        setLoggin({
          isLogged: false,
          message: res.message,
        });
      }

      setLoading(false);
    } else {
      const lastPath = localStorage.getItem("lastPath") || "/admin/dashboard";
      const { email, password } = dataForm;

      const body = {
        email,
        password,
      };

      const {
        isLogged,
        message,
        token,
        expiresIn,
        idToken,
        refreshToken,
        session,
        ChallengeName,
        ChallengeParameters,
        friendlyDevice,
        sessionToken,
      } = await fetchLogin(body);

      if (!isLogged) {
        setLoggin({ isLogged, message });
        setDataForm({
          email: email,
          password: "",
        });
      } else {
        // Fetch details
        if (session && ChallengeName === "CUSTOM_CHALLENGE") {
          let challengeAllowedCreds = JSON.parse(
            ChallengeParameters.allowedCredentials
          );

          let allowedCreds = [];
          let isLegacy = false;

          for (let i = 0, size = challengeAllowedCreds.length; i < size; i++) {
            if (challengeAllowedCreds[i]["legacy"]) {
              isLegacy = true;
            }

            allowedCreds.push({
              id: b64decode(challengeAllowedCreds[i]["id"]),
              type: "public-key",
              transports: challengeAllowedCreds[i]["transports"],
            });
          }
          //----------get creds from security key or platform authenticator
          const signinOptions = {
            challenge: b64decode(ChallengeParameters.challenge),
            timeout: 1800000,
            rpId: isLegacy ? window.location.hostname : FrontendConfig.rp_id,
            userVerification: "discouraged",
            allowCredentials: allowedCreds,
          };

          try {
            //get sign in credentials from authenticator
            //console.log(this.navigator);
            const cred = await navigator.credentials.get({
              publicKey: signinOptions,
            });

            //prepare credentials challenge response
            const credential = {};
            if (cred.response) {
              const clientDataJSON = b64encode(cred.response.clientDataJSON);
              const authenticatorData = b64encode(
                cred.response.authenticatorData
              );
              const signature = b64encode(cred.response.signature);
              const userHandle = b64encode(cred.response.userHandle);

              credential.response = {
                clientDataJSON,
                authenticatorData,
                signature,
                userHandle,
              };

              const bodyWebauthn = {
                credId: cred.id,
                session: session,
                authenticatorData: authenticatorData,
                clientDataJSON: clientDataJSON,
                signature: signature,
                username: ChallengeParameters.USERNAME,
              };

              setLoadingWebauthn(true);

              const { token, expiresIn, idToken, refreshToken, sessionToken } =
                await fetchLoginWebauthn(bodyWebauthn);

              // Fetch profile
              const { redirect, details } = await fetchUserProfile(token);

              setLoadingWebauthn(false);

              setStorageAndRedirect(
                details,
                expiresIn,
                idToken,
                lastPath,
                redirect,
                refreshToken,
                token,
                sessionToken
              );
            } else {
              throw new Error(
                "There was an error trying to authenticate you using this key."
              );
            }
          } catch (e) {
            setLoading(false);
            setLoggin({ isLogged: false, message: e.message });
          }
        } else {
          if (session) {
            setRequestMFA({
              request: true,
              message: null,
              friendlyDevice: friendlyDevice,
              session: session,
            });
          } else {
            // Fetch profile
            const { redirect, details } = await fetchUserProfile(token);

            setStorageAndRedirect(
              details,
              expiresIn,
              idToken,
              lastPath,
              redirect,
              refreshToken,
              token,
              sessionToken
            );
          }
        }
      }

      setLoading(false);
    }
  }

  const handleSubmitEmailNotChecked = async () => {
    if(
      dataForm.emailError ||
      dataForm.emailInvalid ||
      dataForm.email.length == 0
    ){
      return;
    }

    if(!isMobile){
      setIsLoading(true);

      const domain = dataForm.email.split('@')[1];
    
      const { status, details } = await fetchDomainData(domain);
  
      setIsLoading(false);
      
      if(status == "Success"){
        window.location.assign(
          details.app_url
        );
      }else{
        setEmailChecked(true);
      }
    }else{
      setEmailChecked(true);
    }
    

  }


  const handleSubmitMFA = async (pin, cleanUp) => {
    setLoading(true);

    const lastPath = localStorage.getItem("lastPath") || "/admin/dashboard";
    const { email } = dataForm;

    const body = {
      session: requestMFA.session,
      email: email,
      mfaCode: pin,
    };

    const {
      isLogged,
      message,
      token,
      expiresIn,
      idToken,
      refreshToken,
      sessionToken,
    } = await fetchLoginMFA(body);

    if (!isLogged) {
      if (message !== "Invalid code received for user") {
        setDataForm({
          email: "",
          password: "",
        });

        setRequestMFA({
          request: false,
          message: null,
          friendlyDevice: null,
          session: null,
        });
      }

      setLoggin({ isLogged, message });
      cleanUp(false);
      setLoading(false);
    } else {
      // Fetch profile
      const { redirect, details } = await fetchUserProfile(token);

      setLoading(false);

      setStorageAndRedirect(
        details,
        expiresIn,
        idToken,
        lastPath,
        redirect,
        refreshToken,
        token,
        sessionToken
      );

      return;
    }
    setLoading(false);
  };

  const setStorageAndRedirect = (
    details,
    expiresIn,
    idToken,
    lastPath,
    redirect,
    refreshToken,
    token,
    sessionToken
  ) => {
    if (!redirect) {
      const {
        consumption,
        counters,
        email,
        lastname,
        lightTheme,
        sidebarToggle,
        advancedMode,
        name,
        revealMessage,
        sendMessage,
        sso,
        subscription,
        userId,
        isAdmin,
        welcomeScreen,
        publicKeysCred,
        webhook,
        defaultSecretTemplate,
        defaultSecretTemplateReceive,
        keyPair,
        purposeMode,
        instance,
      } = details;

      if (isMobile) {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresIn", expiresIn * 1000 + Date.now());
        localStorage.setItem("userId", userId);
        localStorage.setItem("idToken", idToken);

        window.location = `sharepassapp://app/dashboard/${sessionToken}`;
      } else {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresIn", expiresIn * 1000 + Date.now());
        localStorage.setItem("userId", userId);
        localStorage.setItem("idToken", idToken);

        dispatch2({
          type: dataTypes.profile,
          payload: {
            consumption,
            counters,
            revealMessage,
            sendMessage,
            sso,
            subscription,
            welcomeScreen,
            publicKeysCred,
            webhook,
            defaultSecretTemplate,
            defaultSecretTemplateReceive,
            keyPair,
          },
        });

        dispatch({
          type: types.login,
          payload: {
            name,
            lastName: lastname,
            email,
            userDetails: { name, lastname, email },
            lightTheme,
            isAdmin,
            sidebarToggle,
            advancedMode,
            purposeMode,
            instance,
          },
        });
      }
    }
  };

  const handleSSO = async (idp) => {
    const idpURL = encodeURIComponent(idp);
    const redirect = encodeURIComponent(FrontendConfig.sso.redirect);
    const cid = encodeURIComponent(FrontendConfig.sso.cid);
    const type = encodeURIComponent(FrontendConfig.sso.type);
    const scope = encodeURIComponent(FrontendConfig.sso.scope);
    const state = encodeURIComponent(generateRandomString());
    const verifier = generateRandomString();
    const code_challenge = encodeURIComponent(
      await challenge_from_verifier(verifier)
    );

    localStorage.setItem("idp", idp);
    localStorage.setItem("verifier", verifier);

    window.location.assign(
      `${FrontendConfig.auth_url}/oauth2/authorize?identity_provider=${idpURL}&redirect_uri=${redirect}&response_type=${type}&client_id=${cid}&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`
    );
  };

  const handleTEST = async () => {
    window.location.assign(
      "https://api.tst.sharepass.com/v1/redirect/sso?identity_provider=asd&state=asdas&code_challenge_method=sadas&code_challenge=asdads"
    );
  };
  

  return (
    <>
      {loadingWebauthn && (
        <>
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            showCancel={false}
            showConfirm={false}
          >
            <p className="display-3">Please wait...</p>
          </ReactBSAlert>
        </>
      )}
      <div className="sign-main-content">
        <div className='sign-container'>
          <div className="sign-container-bordered">
            <Form className="sign-container-form" onSubmit={handleSubmit}>
              <>
              <div className="sign-container-title">
                <h1
                  className="sign-container-title-h3"
                  style={{ color: "rgb(14, 16, 26)" }}
                >
                  Sign in
                </h1>
                <div className="sign-container-small">
                  <a
                    className=" "
                    style={{color:"blue"}}
                    data-qa="supplementaryFlowLink"
                    href="/auth/register"
                    target="_parent"
                  >
                    <u>I don't have an account</u>
                  </a>
                </div>
              </div>
                {requestMFA &&
                  requestMFA.request === false &&
                  login &&
                  login.message !== "User is not confirmed." &&
                  !retry && (
                    <>
                      <div>
                        <div>
                          <div className="sign-input-placeholder">
                            <div className={`sign-input-suffix-container ${error.email || dataForm.emailInvalid ? "input-with-errors": ""}`}>
                              
                                
                                <Input
                                  autoFocus
                                  placeholder="Email"
                                  type="email"
                                  className="sign-base-input"
                                  cssModule={{className:''}}
                                  onFocus={(e) =>
                                    setState({ ...state, emailFocus: true })
                                  }
                                  onBlur={(e) =>
                                    setState({ ...state, emailFocus: false })
                                  }
                                  value={dataForm.email}
                                  onChange={(e) => {
                                    validateInput(e);
                                    setError((prevError) => ({
                                      ...prevError,
                                      email: e.target.value.length === 0,
                                    }));
                                  }}
                                  id="email"
                                  autoComplete="none"
                                  disabled={loading}
                                />
                            </div>
                          </div>
                          
                          
                            {(error.email || dataForm.emailInvalid) && (
                              <div className='sign-message-placeholder'>
                                <p className="error-field">
                                  Please, enter a valid email address.
                                </p>
                              </div>
                            )}

                          {emailChecked && <div>
                            <div className="sign-input-placeholder">
                              <div className={`sign-input-suffix-container ${error.password ? "input-with-errors": ""}`}>
                                <InputGroup
                                  className={classnames({
                                    "input-group-focus": state.passFocus,
                                  })}
                                >
                                  
                                  <Input
                                    placeholder="Password"
                                    type="password"
                                    onFocus={(e) =>
                                      setState({ ...state, passFocus: true })
                                    }
                                    onBlur={(e) =>
                                      setState({ ...state, passFocus: false })
                                    }
                                    value={dataForm.password}
                                    onChange={(e) => {
                                      validateInput(e);
                                      setError((prevError) => ({
                                        ...prevError,
                                        password: e.target.value.length === 0,
                                      }));
                                      setLoggin({
                                        isLogged: login.isLogged,
                                        message: "",
                                      });
                                    }}
                                    id="password"
                                    disabled={loading}
                                    className='sign-base-input'
                                  />
                                </InputGroup>
                              </div>
                            </div>
                            
                            
                              {error.password && (
                                <div className='sign-message-placeholder'>
                                  <p className="error-field">Please provide a password</p>
                                </div>
                              )}

                            <div
                              onClick={(e) => handleForgotPassword(e)}
                            >
                              <div className="sign-troubleshoot-link">
                                <button
                                  className="sign-base-unstyled sign-troubleshoot-link-inner"
                                  data-qa="linkForgotPassword"
                                  type="button"
                                >
                                  Can’t sign in?
                                </button>
                              </div>
                            </div>
                          </div>}
                          
                        </div>
                      </div>
                      
                    </>
                  )}

                {requestMFA &&
                  requestMFA.request === true &&
                  login &&
                  login.message !== "User is not confirmed." &&
                  !retry && (
                    <InputPIN
                      requestMFA={requestMFA}
                      handleSubmitMFA={handleSubmitMFA}
                    />
                  )}

                <>
                  {requestMFA && requestMFA.request === false && (
                    <button 
                      disabled={
                        loading ||
                        dataForm.email.length === 0 ||
                        (dataForm.password.length === 0 &&
                          login.message !== "User is not confirmed." &&
                          !retry && emailChecked) || dataForm.emailInvalid
                          ? true
                          : false}
                      data-qa="btnSignupSubmit" 
                      className={`btn_Submit  ${
                        loading ||
                        dataForm.email.length === 0 ||
                        (dataForm.password.length === 0 &&
                          login.message !== "User is not confirmed." &&
                          !retry && emailChecked) || dataForm.emailInvalid
                          ? "disabled"
                          : ""
                      } `}
                      type="submit"
                    >
                      {login &&
                      (login.message === "User is not confirmed." || retry)
                        ? "Resend validation link"
                        : emailChecked?  "Sign In": <span id="btnsignin">Continue</span>}
                    </button>
                  )}

                  
                    {login && login.message && (
                      <div className='sign-message-placeholder'>
                        <p className={`error-p ${login.status ? "p-info" : ""}`}>
                          {login.message}
                        </p>
                      </div>
                    )}
                  

                  <div><hr/></div>
                  

                  {
                    login &&
                    login.message !== "User is not confirmed." &&
                    !retry && (
                      <div>
                        <button
                          type="button"
                          data-qa="btnGoogleAuth"
                          className="sign-base-basic sso"
                          onClick={() => handleSSO("Google")}
                        >
                          <span className="sign-left-icon">
                            <svg width={16} height={16} viewBox="0 0 16 16" fill="none">
                              <g clipPath="url(#clip0)">
                                <path
                                  d="M16.0008 8.17753C16.0008 7.51976 15.9463 7.03976 15.8285 6.54199H8.16406V9.51085H12.6629C12.5722 10.2486 12.0824 11.3598 10.994 12.1064L10.9787 12.2058L13.4021 14.0456L13.5699 14.062C15.1119 12.6664 16.0008 10.6131 16.0008 8.17753Z"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M8.1636 15.9999C10.3676 15.9999 12.218 15.2887 13.5695 14.0621L10.9935 12.1065C10.3042 12.5776 9.37899 12.9065 8.1636 12.9065C6.00489 12.9065 4.17272 11.5109 3.5196 9.58203L3.42386 9.59L0.904047 11.5011L0.871094 11.5909C2.21348 14.2042 4.97084 15.9999 8.1636 15.9999Z"
                                  fill="#34A853"
                                />
                                <path
                                  d="M3.52021 9.5824C3.34788 9.08463 3.24815 8.55126 3.24815 8.00017C3.24815 7.44903 3.34788 6.91572 3.51115 6.41795L3.50658 6.31193L0.95518 4.37012L0.871703 4.40903C0.31844 5.49349 0.000976562 6.71129 0.000976562 8.00017C0.000976562 9.28906 0.31844 10.5068 0.871703 11.5913L3.52021 9.5824Z"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M8.16364 3.09331C9.6965 3.09331 10.7305 3.7422 11.3201 4.28446L13.6239 2.08C12.209 0.791114 10.3677 0 8.16364 0C4.97087 0 2.21349 1.79554 0.871094 4.40885L3.51054 6.41777C4.17274 4.48888 6.00492 3.09331 8.16364 3.09331Z"
                                  fill="#EB4335"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0">
                                  <rect width={16} height={16} fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                          <span className="sign-base-text ">
                            Sign in with Google
                          </span>
                          <span className="sign-right-icon">
                            <svg
                              width={8}
                              height={12}
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 0.5L6.5 6L1 11.5"
                                stroke="currentColor"
                                strokeLinecap="round"
                              />
                            </svg>
                          </span>
                        </button>
                        
                        <button
                          type="button"
                          data-qa="btnAppleAuth"
                          className="sign-base-basic sso"
                          onClick={() => handleSSO("SignInWithApple")}
                        >
                          <span className="sign-left-icon">
                            <svg width={16} height={16} viewBox="0 0 14 15" fill="none">
                              <path
                                d="M12.8926 5.06757C12.824 5.10896 11.1909 5.98231 11.1909 7.91872C11.2679 10.1271 13.2518 10.9015 13.2858 10.9015C13.2518 10.9429 12.9863 11.9565 12.1999 13.0191C11.5758 13.9343 10.883 14.8571 9.83114 14.8571C8.83056 14.8571 8.47137 14.2471 7.31685 14.2471C6.07699 14.2471 5.72618 14.8571 4.7769 14.8571C3.725 14.8571 2.98098 13.8849 2.32285 12.9782C1.46783 11.7916 0.741095 9.92931 0.715439 8.14122C0.698149 7.19371 0.886665 6.26231 1.36521 5.4712C2.04063 4.36674 3.24646 3.61701 4.56329 3.59229C5.57224 3.5595 6.4702 4.25979 7.08595 4.25979C7.67603 4.25979 8.77924 3.59229 10.0275 3.59229C10.5662 3.59282 12.003 3.74922 12.8926 5.06757ZM7.00061 3.40311C6.82102 2.53782 7.31685 1.67253 7.77866 1.12057C8.36875 0.453067 9.30073 0 10.1044 0C10.1557 0.865287 9.83058 1.71391 9.24942 2.33198C8.72793 2.99948 7.82997 3.50199 7.00061 3.40311Z"
                                fill="#0E101A"
                              />
                            </svg>
                          </span>
                          <span className="sign-base-text ">
                            Sign in with Apple
                          </span>
                          <span className="sign-right-icon">
                            <svg
                              width={8}
                              height={12}
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 0.5L6.5 6L1 11.5"
                                stroke="currentColor"
                                strokeLinecap="round"
                              />
                            </svg>
                          </span>
                        </button>

                        <button
                          type="button"
                          data-qa="btnWWPassAuth"
                          className="sign-base-basic sso"
                          onClick={() => handleSSO("WWPass")}
                        >
                          <span className="sign-left-icon">
                            <img style={{width: '20px'}} src={wwpass} />
                          </span>
                          <span className="sign-base-text ">
                            Sign in with WWPass
                          </span>
                          <span className="sign-right-icon">
                            <svg
                              width={8}
                              height={12}
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 0.5L6.5 6L1 11.5"
                                stroke="currentColor"
                                strokeLinecap="round"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                    )}

                  {false &&
                    login &&
                    login.message !== "User is not confirmed." &&
                    !retry && (
                      <MobileSSOButtons
                        handleSSO={handleSSO}
                        loading={loading}
                      />
                    )}

                  {false && window.location.hostname === "app.tst.sharepass.com" /*||
                      window.location.hostname === "localhost"*/ && (
                    <>
                      <SSOButton
                        handleSSO={() => {
                          handleSSO("AzureAD");
                        }}
                        icon={faMicrosoft}
                        loading={loading}
                        color="twitter"
                        text="Sign in with "
                      />
                      <SSOButton
                        handleSSO={() => {
                          handleTEST();
                        }}
                        loading={loading}
                        color="twitter"
                        text="Test"
                      ></SSOButton>{" "}
                    </>
                  )}

                  {false && (
                    <div>
                      <hr />
                      <Row className="mb-1">
                        <Col sm="12">
                          <div className="text-center">
                            <FontAwesomeIcon
                              icon={faUserSecret}
                              className="text-center fa-2x"
                            />
                          </div>
                          <div className="text-center">
                            <small className="text-center">
                              To strengthen the security it is recommended to
                              use the browser in private/incognito mode.
                            </small>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  
                  {login &&
                    (login.message === "User is not confirmed." || retry) && (
                      <>
                        <div className="text-center">
                          <h6>
                            <a
                              className="link footer-link"
                              href="/auth/login"
                              onClick={(e) => handleGoBack(e)}
                            >
                              <i className="fas fa-arrow-left"></i> Go back
                            </a>
                          </h6>
                        </div>
                      </>
                    )}
                </>
              </>
        
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
