import {
  faApple,
  faGoogle,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import FrontendConfig from "../../assets/js/config";
import useAPIError from "../../commons/hooks/useAPIError";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { dataTypes, types } from "../../context/types";
import {
  fetchLogin,
  fetchLoginMFA,
  fetchLoginWebauthn,
} from "../../services/auth";
import {
  b64decode,
  b64encode,
  challenge_from_verifier,
  generateRandomString,
} from "../../services/common";
import {
  fetchResendVerifyAccount,
  fetchUserProfile,
} from "../../services/services";
import InputPIN from "../components/InputPIN";
import { MobileSSOButtons } from "../components/MobileLogin/MobileSSOButtons";
import SSOButton from "../components/SSOButton";

const LoginDefault = () => {
  const history = useHistory();

  /**
   * If the login page has been opened from the mobile app we need to add a localstorage flag.
   */
  const isMobile = localStorage.getItem("isMobile") ? true : false;

  // Notify
  const { addNotify } = useAPIError();
  // Login
  const { dispatch } = useContext(Context);
  const { dispatch2 } = useContext(DataContext);

  const [state, setState] = useState({});
  useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const handleNewAccount = (e) => {
    e.preventDefault();
    history.push("/auth/register");
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    setRetry(false);
    setLoggin({ isLogged: login.isLogged, message: null });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    history.push("/auth/forgot-password");
  };

  const [dataForm, setDataForm] = useState({
    email: "",
    password: "",
  });

  const validateInput = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case "email":
        setDataForm((prevForm) => ({
          ...prevForm,
          email: value,
        }));
        break;
      case "password":
        setDataForm((prevForm) => ({
          ...prevForm,
          password: value,
        }));
        break;
      default:
        break;
    }
  };

  const [retry, setRetry] = useState(false);
  const [requestMFA, setRequestMFA] = useState({
    request: false,
    message: null,
    friendlyDevice: null,
    session: null,
  });
  const [login, setLoggin] = useState({ isLogged: false, message: null });
  const [loading, setLoading] = useState(false);
  const [loadingWebauthn, setLoadingWebauthn] = useState(false);

  const [error, setError] = useState({ email: false, password: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (login && (login.message === "User is not confirmed." || retry)) {
      setRetry(true);
      const res = await fetchResendVerifyAccount({ email: dataForm.email });

      if (res.status === "Success") {
        setLoggin((prevLogin) => ({
          ...prevLogin,
          message: res.message,
          status: true,
        }));
      } else if (res.errorType === "LimitExceededException") {
        setLoggin((prevLogin) => ({
          ...prevLogin,
          message: res.errorMessage,
        }));
      } else if (res.status === "Forbidden") {
        setLoggin({
          isLogged: false,
          message: res.message,
        });
      }

      setLoading(false);
    } else {
      const lastPath = localStorage.getItem("lastPath") || "/admin/dashboard";
      const { email, password } = dataForm;

      const body = {
        email,
        password,
      };

      const {
        isLogged,
        message,
        token,
        expiresIn,
        idToken,
        refreshToken,
        session,
        ChallengeName,
        ChallengeParameters,
        friendlyDevice,
        sessionToken,
      } = await fetchLogin(body);

      if (!isLogged) {
        setLoggin({ isLogged, message });
        setDataForm({
          email: email,
          password: "",
        });
      } else {
        // Fetch details
        if (session && ChallengeName === "CUSTOM_CHALLENGE") {
          let challengeAllowedCreds = JSON.parse(
            ChallengeParameters.allowedCredentials
          );

          let allowedCreds = [];
          let isLegacy = false;

          for (let i = 0, size = challengeAllowedCreds.length; i < size; i++) {
            if (challengeAllowedCreds[i]["legacy"]) {
              isLegacy = true;
            }

            allowedCreds.push({
              id: b64decode(challengeAllowedCreds[i]["id"]),
              type: "public-key",
              transports: challengeAllowedCreds[i]["transports"],
            });
          }
          //----------get creds from security key or platform authenticator
          const signinOptions = {
            challenge: b64decode(ChallengeParameters.challenge),
            timeout: 1800000,
            rpId: isLegacy ? window.location.hostname : FrontendConfig.rp_id,
            userVerification: "discouraged",
            allowCredentials: allowedCreds,
          };

          try {
            //get sign in credentials from authenticator
            //console.log(this.navigator);
            const cred = await navigator.credentials.get({
              publicKey: signinOptions,
            });

            //prepare credentials challenge response
            const credential = {};
            if (cred.response) {
              const clientDataJSON = b64encode(cred.response.clientDataJSON);
              const authenticatorData = b64encode(
                cred.response.authenticatorData
              );
              const signature = b64encode(cred.response.signature);
              const userHandle = b64encode(cred.response.userHandle);

              credential.response = {
                clientDataJSON,
                authenticatorData,
                signature,
                userHandle,
              };

              const bodyWebauthn = {
                credId: cred.id,
                session: session,
                authenticatorData: authenticatorData,
                clientDataJSON: clientDataJSON,
                signature: signature,
                username: ChallengeParameters.USERNAME,
              };

              setLoadingWebauthn(true);

              const { token, expiresIn, idToken, refreshToken, sessionToken } =
                await fetchLoginWebauthn(bodyWebauthn);

              // Fetch profile
              const { redirect, details } = await fetchUserProfile(token);

              setLoadingWebauthn(false);

              setStorageAndRedirect(
                details,
                expiresIn,
                idToken,
                lastPath,
                redirect,
                refreshToken,
                token,
                sessionToken
              );
            } else {
              throw new Error(
                "There was an error trying to authenticate you using this key."
              );
            }
          } catch (e) {
            setLoading(false);
            setLoggin({ isLogged: false, message: e.message });
          }
        } else {
          if (session) {
            setRequestMFA({
              request: true,
              message: null,
              friendlyDevice: friendlyDevice,
              session: session,
            });
          } else {
            // Fetch profile
            const { redirect, details } = await fetchUserProfile(token);

            setStorageAndRedirect(
              details,
              expiresIn,
              idToken,
              lastPath,
              redirect,
              refreshToken,
              token,
              sessionToken
            );
          }
        }
      }

      setLoading(false);
    }
  };

  const handleSubmitMFA = async (pin, cleanUp) => {
    setLoading(true);

    const lastPath = localStorage.getItem("lastPath") || "/admin/dashboard";
    const { email } = dataForm;

    const body = {
      session: requestMFA.session,
      email: email,
      mfaCode: pin,
    };

    const {
      isLogged,
      message,
      token,
      expiresIn,
      idToken,
      refreshToken,
      sessionToken,
    } = await fetchLoginMFA(body);

    if (!isLogged) {
      if (message !== "Invalid code received for user") {
        setDataForm({
          email: "",
          password: "",
        });

        setRequestMFA({
          request: false,
          message: null,
          friendlyDevice: null,
          session: null,
        });
      }

      setLoggin({ isLogged, message });
      cleanUp(false);
      setLoading(false);
    } else {
      // Fetch profile
      const { redirect, details } = await fetchUserProfile(token);

      setLoading(false);

      setStorageAndRedirect(
        details,
        expiresIn,
        idToken,
        lastPath,
        redirect,
        refreshToken,
        token,
        sessionToken
      );

      return;
    }
    setLoading(false);
  };

  const setStorageAndRedirect = (
    details,
    expiresIn,
    idToken,
    lastPath,
    redirect,
    refreshToken,
    token,
    sessionToken
  ) => {
    if (!redirect) {
      const {
        consumption,
        counters,
        email,
        lastname,
        lightTheme,
        sidebarToggle,
        advancedMode,
        name,
        revealMessage,
        sendMessage,
        sso,
        subscription,
        userId,
        isAdmin,
        welcomeScreen,
        publicKeysCred,
        webhook,
        defaultSecretTemplate,
        defaultSecretTemplateReceive,
        keyPair,
        purposeMode,
        instance,
      } = details;

      if (isMobile) {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresIn", expiresIn * 1000 + Date.now());
        localStorage.setItem("userId", userId);
        localStorage.setItem("idToken", idToken);

        window.location = `sharepassapp://app/dashboard/${sessionToken}`;
      } else {
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("expiresIn", expiresIn * 1000 + Date.now());
        localStorage.setItem("userId", userId);
        localStorage.setItem("idToken", idToken);

        dispatch2({
          type: dataTypes.profile,
          payload: {
            consumption,
            counters,
            revealMessage,
            sendMessage,
            sso,
            subscription,
            welcomeScreen,
            publicKeysCred,
            webhook,
            defaultSecretTemplate,
            defaultSecretTemplateReceive,
            keyPair,
          },
        });

        dispatch({
          type: types.login,
          payload: {
            name,
            lastName: lastname,
            email,
            userDetails: { name, lastname, email },
            lightTheme,
            isAdmin,
            sidebarToggle,
            advancedMode,
            purposeMode,
            instance,
          },
        });
      }
    }
  };

  const handleSSO = async (idp) => {
    const idpURL = encodeURIComponent(idp);
    const redirect = encodeURIComponent(FrontendConfig.sso.redirect);
    const cid = encodeURIComponent(FrontendConfig.sso.cid);
    const type = encodeURIComponent(FrontendConfig.sso.type);
    const scope = encodeURIComponent(FrontendConfig.sso.scope);
    const state = encodeURIComponent(generateRandomString());
    const verifier = generateRandomString();
    const code_challenge = encodeURIComponent(
      await challenge_from_verifier(verifier)
    );

    localStorage.setItem("idp", idp);
    localStorage.setItem("verifier", verifier);

    window.location.assign(
      `${FrontendConfig.auth_url}/oauth2/authorize?identity_provider=${idpURL}&redirect_uri=${redirect}&response_type=${type}&client_id=${cid}&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256`
    );
  };

  const handleTEST = async () => {
    window.location.assign(
      "https://api.tst.sharepass.com/v1/redirect/sso?identity_provider=asd&state=asdas&code_challenge_method=sadas&code_challenge=asdads"
    );
  };

  return (
    <>
      {loadingWebauthn && (
        <>
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            showCancel={false}
            showConfirm={false}
          >
            <p className="display-3">Please wait...</p>
          </ReactBSAlert>
        </>
      )}
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6" sm="8" xs="10">
            <Form className="form" onSubmit={handleSubmit}>
              <Card className="card-login card-white">
                <CardHeader
                  className={`card-header-sp ${
                    isMobile ? "card-header-sp-mobile" : ""
                  }`}
                >
                  <img
                    className="logo-login"
                    alt="..."
                    src={require("../../assets/img/sp.png").default}
                  />
                </CardHeader>
                {requestMFA &&
                  requestMFA.request === false &&
                  login &&
                  login.message !== "User is not confirmed." &&
                  !retry && (
                    <CardBody>
                      <InputGroup
                        className={classnames({
                          "input-group-focus": state.emailFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoFocus
                          placeholder="Email"
                          type="email"
                          onFocus={(e) =>
                            setState({ ...state, emailFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, emailFocus: false })
                          }
                          value={dataForm.email}
                          onChange={(e) => {
                            validateInput(e);
                            setError((prevError) => ({
                              ...prevError,
                              email: e.target.value.length === 0,
                            }));
                          }}
                          id="email"
                          autoComplete="none"
                          disabled={loading}
                        />
                      </InputGroup>
                      {error.email && (
                        <p className="error-field">
                          Please, enter a valid email address.
                        </p>
                      )}
                      <InputGroup
                        className={classnames({
                          "input-group-focus": state.passFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          onFocus={(e) =>
                            setState({ ...state, passFocus: true })
                          }
                          onBlur={(e) =>
                            setState({ ...state, passFocus: false })
                          }
                          value={dataForm.password}
                          onChange={(e) => {
                            validateInput(e);
                            setError((prevError) => ({
                              ...prevError,
                              password: e.target.value.length === 0,
                            }));
                            setLoggin({
                              isLogged: login.isLogged,
                              message: "",
                            });
                          }}
                          id="password"
                          disabled={loading}
                        />
                      </InputGroup>
                      {error.password && (
                        <p className="error-field">Please provide a password</p>
                      )}
                    </CardBody>
                  )}

                {requestMFA &&
                  requestMFA.request === true &&
                  login &&
                  login.message !== "User is not confirmed." &&
                  !retry && (
                    <InputPIN
                      requestMFA={requestMFA}
                      handleSubmitMFA={handleSubmitMFA}
                    />
                  )}
                {login && (
                  <p className={`error-p ${login.status ? "p-info" : ""}`}>
                    {login.message}
                  </p>
                )}
                <CardFooter>
                  {requestMFA && requestMFA.request === false && (
                    <Button
                      block
                      className={`mb-3 ${
                        loading ||
                        dataForm.email.length === 0 ||
                        (dataForm.password.length === 0 &&
                          login.message !== "User is not confirmed." &&
                          !retry)
                          ? "disabled"
                          : ""
                      } `}
                      color="primary"
                      type="submit"
                    >
                      {login &&
                      (login.message === "User is not confirmed." || retry)
                        ? "Resend validation link"
                        : "Sign In"}
                    </Button>
                  )}
                  {!isMobile &&
                    login &&
                    login.message !== "User is not confirmed." &&
                    !retry && (
                      <>
                        <hr />
                        <SSOButton
                          id="ssoGoogleBtn"
                          handleSSO={() => {
                            handleSSO("Google");
                          }}
                          icon={faGoogle}
                          loading={loading}
                          color="google"
                          text="Sign in with Google"
                        />

                        <UncontrolledTooltip
                          delay={0}
                          target="ssoGoogleBtn"
                          placement="top"
                        >
                          Sign in with Google
                        </UncontrolledTooltip>

                        <SSOButton
                          id="ssoWWPassBtn"
                          handleSSO={() => {
                            handleSSO("WWPass");
                          }}
                          loading={loading}
                          color="twitter"
                          iconImg={
                            require("../../assets/img/wwpass.png").default
                          }
                          text="Sign in with WWPass"
                        />

                        <UncontrolledTooltip
                          delay={0}
                          target="ssoWWPassBtn"
                          placement="top"
                        >
                          Sign in with WWPass
                        </UncontrolledTooltip>

                        <SSOButton
                          id="ssoAppleBtn"
                          handleSSO={() => {
                            handleSSO("SignInWithApple");
                          }}
                          loading={loading}
                          color="black"
                          icon={faApple}
                          text="Sign in with Apple"
                        />

                        <UncontrolledTooltip
                          delay={0}
                          target="ssoAppleBtn"
                          placement="top"
                        >
                          Sign in with Apple
                        </UncontrolledTooltip>
                      </>
                    )}

                  {isMobile &&
                    login &&
                    login.message !== "User is not confirmed." &&
                    !retry && (
                      <MobileSSOButtons
                        handleSSO={handleSSO}
                        loading={loading}
                      />
                    )}

                  {window.location.hostname === "app.tst.sharepass.com" /*||
                      window.location.hostname === "localhost"*/ && (
                    <>
                      <SSOButton
                        handleSSO={() => {
                          handleSSO("AzureAD");
                        }}
                        icon={faMicrosoft}
                        loading={loading}
                        color="twitter"
                        text="Sign in with "
                      />
                      <SSOButton
                        handleSSO={() => {
                          handleTEST();
                        }}
                        loading={loading}
                        color="twitter"
                        text="Test"
                      ></SSOButton>{" "}
                    </>
                  )}

                  {!isMobile && (
                    <div>
                      <hr />
                      <Row className="mb-1">
                        <Col sm="12">
                          <div className="text-center">
                            <FontAwesomeIcon
                              icon={faUserSecret}
                              className="text-center fa-2x"
                            />
                          </div>
                          <div className="text-center">
                            <small className="text-center">
                              To strengthen the security it is recommended to
                              use the browser in private/incognito mode.
                            </small>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {login &&
                    login.message !== "User is not confirmed." &&
                    !retry && (
                      <>
                        <hr />
                        <div className="pull-left">
                          <h6>
                            <a
                              className="link footer-link"
                              href="/auth/register"
                              onClick={(e) => handleNewAccount(e)}
                            >
                              Create Account
                            </a>
                          </h6>
                        </div>
                        <div className="pull-right">
                          <h6>
                            <a
                              className="link footer-link"
                              href="/auth/forgot-password"
                              onClick={(e) => handleForgotPassword(e)}
                            >
                              Forgot your password?
                            </a>
                          </h6>
                        </div>
                      </>
                    )}
                  {login &&
                    (login.message === "User is not confirmed." || retry) && (
                      <>
                        <div className="text-center">
                          <h6>
                            <a
                              className="link footer-link"
                              href="/auth/login"
                              onClick={(e) => handleGoBack(e)}
                            >
                              <i className="fas fa-arrow-left"></i> Go back
                            </a>
                          </h6>
                        </div>
                      </>
                    )}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  );
};

/**
 * Environment override file if exists
 */

const tryRequire = () => {
  try {
    const env = FrontendConfig.env;
    return require("../../envs/" + env + "/views/pages/Login");
  } catch (err) {
    return null;
  }
};

const Login = tryRequire() ? tryRequire().default : LoginDefault;

export default Login;
