import { faCog, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  Collapse,
  CustomInput,
  FormGroup,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import { Context } from "../../context/auth/Context";
import { DataContext } from "../../context/data/DataContext";
import { dataTypes, types } from "../../context/types";
import {
  fetchTemplates,
  fetchUpdateGeneralSettings,
  fetchUserProfile,
  fetchSecretSchemas,
} from "../../services/services";
import { fetchLabels } from "../../services/settings";
import { defaultValues } from "../../utils/secret";
import { parseClassLabels } from "../../utils/settings";
import { parseTemplates } from "../../utils/templates";
import { CustomModalLabel } from "../components/CustomModalLabel";
import LiteNewSecretForm from "../components/LiteNewSecretForm";
import { NewSecretForm } from "../components/NewSecretForm";
import InstanceConfig from "../../assets/js/instanceConfig";

const NewSecret = () => {
  const history = useHistory();

  // Get name from context
  const { user, dispatch } = useContext(Context);

  // Context for profile
  const { profile, dispatch2 } = useContext(DataContext);

  /* Show label modal */
  const [modalClassic, setModalClassic] = useState(false);

  let storageUser = localStorage.getItem("user");
  let tempUser: any = storageUser
    ? JSON.parse(storageUser)
    : { advancedMode: false };
  const [mode, setMode] = useState(tempUser.advancedMode || user.purposeMode); //false for lite mode, true for advanced

  const defaultTemplateIsSet = function(){
    return profile &&
    profile.defaultSecretTemplate &&
    profile.defaultSecretTemplate.defaultTemplate &&
    user.purposeMode === 0
    ||
    profile &&
    profile.defaultSecretTemplateReceive &&
    profile.defaultSecretTemplateReceive.defaultTemplate &&
    user.purposeMode === 1;
  };

  const getDefaultSecretTemplateValue = function(field:string, empty: any){
    if(defaultTemplateIsSet()){
      if(user.purposeMode === 1){
        return profile.defaultSecretTemplateReceive.defaultTemplate[field];
      }else{
        return profile.defaultSecretTemplate.defaultTemplate[field];
      }
    }else {
      return empty;
    }
  }

  const [customSecretSchemas, setCustomSecretSchemas] = useState([]);
  useEffect(() => {
    const getSecretSchemas = async () => {
      const { details, status, redirect } = await fetchSecretSchemas();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        setCustomSecretSchemas(details);
      }
    };
    
    if (user.purposeMode === 0 && customSecretSchemas.length === 0 && InstanceConfig().customSecretSchema) getSecretSchemas();
  }, []);

  const [customSecretSchemaValue, setCustomSecretSchemaValue] = useState({});
  /*
   * Select for templates.
   * When user select a template, we will fill the private, public
   * description (if the template bring that info) & security settings to.
   */
  const [template, setTemplate] = useState(
    defaultTemplateIsSet()
      ? {
          codeName: "Default",
          color: "",
          details: user.purposeMode === 1 ? profile.defaultSecretTemplateReceive.defaultTemplate : profile.defaultSecretTemplate.defaultTemplate,
          label: "Default",
          starred: false,
          value: "Default",
        }
      : []
  );

  const [templates, setTemplates] = useState([]);
  useEffect(() => {
    const getTemplates = async () => {
      const { details, status, redirect } = await fetchTemplates(true);

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        let defaultTemplateAux = {};
        if (template) {
          defaultTemplateAux = template;
        }

        const parsedTemplates = [
          defaultTemplateAux,
          ...parseTemplates(details, true),
        ];

        setTemplates([
          {
            label: "Select an option",
            value: "Select an option",
            isDisabled: true,
          },
          ...parsedTemplates,
        ]);
      }
    };

    if (templates.length === 0) getTemplates();
  }, [templates]);

  const [multipleSelect, setmultipleSelect] = useState([]);
  const [labelOptions, setLabelOptions] = useState([]);

  /*useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        if (details.length > 0) {
          const auxLabels = parseClassLabels(details);
          setLabelOptions([
            {
              label: "Select an option",
              value: "Select an option",
              isDisabled: true,
            },
            ...auxLabels,
          ]);
        }
      }
    };

    if (labelOptions.length === 0) getLabels();
  }, [labelOptions]);*/

  //console.log(profile);
  /*
   * We use this condition to put only one label, if we want to add more labels
   * in the future, we will need to delete this condition.
   */
  useEffect(() => {
    if (multipleSelect.length > 1) {
      setmultipleSelect((prevValues) => [prevValues[1]]);
    }

    setConfig((prevConfig) => ({
      ...prevConfig,
      label: {
        name: multipleSelect && multipleSelect[0] && multipleSelect[0].label,
        color: multipleSelect && multipleSelect[0] && multipleSelect[0].color,
      },
    }));
  }, [multipleSelect]);

  const [config, setConfig] = useState({
    lockedByOwner: false,
    data: null,
    error: false,
    availability: {
      isSwitched: false,
      isSwitched2: true,
      dateTimeEnd: undefined,
      dateTimeStart: undefined,
      opt: "Right now",
      opt2: "Days",
      qty: undefined,
      qty2: 7,
    },
    authorization: { opt: "No pin", pin: 0 },
  });

  /*
   * We need to handle values like password, message, qr code (one field) and credential object
   * (more than one fild).
   * Because, when we load a template, we are reinitializing the form to fill private and public
   * description. But at the same time, we delete the password/message/qrcode/credential inputs.
   */
  const [prevValuesAux, setPrevValuesAux] = useState({
    ...defaultValues,
    ...{
      private: getDefaultSecretTemplateValue("privateDescription", ""),
      public: getDefaultSecretTemplateValue("publicDescription", ""),
    },
  });

  /*
   * We need to fill config and default values for private & public description
   */
  const [initialValues, setInitialValues] = useState({
    ...defaultValues,
    ...{
      private: getDefaultSecretTemplateValue("privateDescription", ""),
      public: getDefaultSecretTemplateValue("publicDescription", ""),
    },
  });

  const [qty2Aux, setQtyAux] = useState(0);
  useEffect(() => {
    if (template.value) {
      const { details } = template;

      // Validate private & public description
      setInitialValues((prevValues) => ({
        ...prevValuesAux,
        private: details.privateDescription ? details.privateDescription : "",
        public: details.publicDescription ? details.publicDescription : "",
      }));

      // Start date
      let opt: string = "Right now";
      let qty: any;
      let dateTimeStart: any;

      // TTL - By default is relative in days
      let opt2: string = "Days";
      let qty2: any;
      let dateTimeEnd: any;

      // AVAILABLE FROM
      if (details && details.availableFrom) {
        if (details.availableFrom.absolute) {
          opt = "Specific date";
          dateTimeStart = details.availableFrom.absolute * 1000;
        } else if (details.availableFrom.relative) {
          const relative = details.availableFrom.relative;

          if (relative % 86400 === 0) {
            opt = "Days";
            qty = relative / 86400;
          } else if (relative % 3600 === 0) {
            opt = "Hours";
            qty = relative / 3600;
          } else if (relative % 60 === 0) {
            opt = "Minutes";
            qty = relative / 60;
          }
        }
      }

      // --------------TTL--------------
      if (details && details.ttl) {
        if (details.ttl.absolute) {
          opt2 = "Specific date";
          dateTimeEnd = details.ttl.absolute * 1000;
        } else if (details.ttl.relative) {
          const relative = details.ttl.relative;

          if (relative % 86400 === 0) {
            opt2 = "Days";
            qty2 = relative / 86400;
          } else if (relative % 3600 === 0) {
            opt2 = "Hours";
            qty2 = relative / 3600;
          } else if (relative % 60 === 0) {
            opt2 = "Minutes";
            qty2 = relative / 60;
          }
        } else {
          opt2 = "Never expire";
        }
      }

      if (details.label && details.label.codeName && details.label.color) {
        let labelData: any = parseClassLabels([
          {
            codeName: details.label.codeName,
            color: details.label.color,
          },
        ]);
        setmultipleSelect(labelData);
      } else {
        setmultipleSelect([]);
      }

      setQtyAux(qty2);

      let availability: any = {
        isSwitched: !details.otl,
        isSwitched2: details.lockAfterUse || false,
        dateTimeEnd,
        dateTimeStart,
        opt,
        opt2,
        qty,
        qty2,
      };

      let pin: any;
      if (details.pin && details.pin.static) pin = details.pin.static;
      else if (details.pin && details.pin.dynamic) pin = details.pin.dynamic;

      let authobj: any = undefined; //{ opt: "No pin" };
      if (details.pin && (details.pin.static || details.pin.dynamic)) {
        authobj = {
          opt: details.pin && details.pin.static ? "Static" : "Dynamic",
          pin,
        };
      }else if(details.keys && details.keys.length > 0){

        for(let i = 0, size = details.keys.length; i < size; i++){
          details.keys[i].labelAux = details.keys[i].label;
          details.keys[i].valueAux = details.keys[i].value;
          details.keys[i].label = details.keys[i].name;
          details.keys[i].value = details.keys[i].id;
        }

        authobj = {
          opt: "Security key",
          keys: details.keys,
        };
      }

      setConfig((prevConfig) => ({
        ...prevConfig,
        ips: details.ipList ? details.ipList : [],
        lockedByOwner: details.lockedByOwner,
        /*typeof details.lockedByOwner != "undefined"
            ? details.lockedByOwner
            : false*/ authorization: authobj,
        availability,
        update: true,
      }));
    }
  }, [template]);

  useEffect(() => {
    const updateUserDetails = async () => {
      if (typeof profile.sso === "undefined") {
        const { status, details, redirect } = await fetchUserProfile();
        //const { status, details, redirect } = await fetchUserDetails();
        if (redirect) {
          dispatch({
            type: types.logout,
          });
          history.push("/auth/login");
        } else if (status === "Success" && details) {
          /*setUserDetails(details);
          setIsSwitched(
            details.webhook && details.webhook.active
              ? details.webhook.active
              : false
          );
          setHasSSO(details.sso === true);
          setHasWebhook(details.webhook && details.webhook.url ? true : false);
          setIsCredKeys(details.publicKeysCred);*/

          dispatch2({
            type: dataTypes.profile,
            payload: { ...details },
          });
        }
      } else {
        //console.log(profile);
      }
    };

    updateUserDetails();
  }, []);

  const goToTemplates = () => {
    history.push("/admin/template/new-template");
  };

  const addNewLabel = () => {
    setModalClassic(!modalClassic);
  };

  const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "inline-block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: "red" }),
    option: (styles, { data }) => {
      const color = data.color;

      return {
        ...styles,
        /* color: `${color} !important`, */
        ...dot(color),
      };
    },
    input: (styles) => ({
      ...styles,
      color: "#fff !important",
    }),
    placeholder: (styles) => ({ ...styles, value: "Chose template" }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  /*
   * Listen close modal
   * After close modal (maybe succes or cancelled by the user) we need to update the array of labels
   * In the future, we will remove, and by replaced by hooks of redux or context.
   */
  useEffect(() => {
    const getLabels = async () => {
      const { status, details, redirect } = await fetchLabels();

      if (redirect) {
        dispatch({
          type: types.logout,
        });
        history.push("/auth/login");
      } else if (status === "Success") {
        const auxLabels = parseClassLabels(details);
        setLabelOptions([
          {
            label: "Select an option",
            value: "Select an option",
            isDisabled: true,
          },
          ...auxLabels,
        ]);
      }
    };

    if (!modalClassic) getLabels();
  }, [modalClassic]);

  const handleChangeMode = async () => {

    if(mode && profile){
      setTemplate({
        codeName: "Default",
        color: "",
        details: user.purposeMode === 1? profile.defaultSecretTemplateReceive.defaultTemplate : profile.defaultSecretTemplate.defaultTemplate,
        label: "Default",
        starred: false,
        value: "Default",
      });
    }


    setMode((prev: boolean) => !prev);

    try {
      const { status } = await fetchUpdateGeneralSettings({
        advancedMode: !mode,
      });

      /*@TODO: Temporary patch */
      let userTemp: any = localStorage.getItem("user");
      if (userTemp) {
        userTemp = JSON.parse(userTemp);
        userTemp.advancedMode = !mode;
        localStorage.setItem("user", JSON.stringify(userTemp));
      }

      /*dispatch({
        type: types.userDetails,
        payload: {
          ...user,
          advancedMode: !mode,
        },
      });*/
    } catch (e) {}
  };

  const goToSettingsEnvelope = function (){

    dispatch2({
      type: dataTypes.profile,
      payload: { profileTabName: "envelope" },
    });

    history.push("/admin/settings/profile");
  };

  const [openedCollapseTemplate, setOpenedCollapseTemplate] = useState(false);
  const [openedCollapseSecret, setOpenedCollapseSecret] = useState(true);
  const [openedCollapseSettings, setOpenedCollapseSettings] = useState(false);

  return (
    <>
      <div className="content new-secret">
        <Row>
          <Col md="12">
            <Card>
            {profile && profile.defaultSecretTemplate && <CardBody>
                {profile.keyPair && profile.keyPair.alg && user.purposeMode === 1 || !user.purposeMode ?
                
                <div>
                  <Row>
                    <Col md="12">
                      {!user.purposeMode && <div className="pull-right">
                        <div className="d-flex align-items-center ns-mode-switch">
                          <span className="mr-2">
                            <h4>Advanced mode</h4>
                          </span>
                          <CustomInput
                            type="switch"
                            id="switchMode"
                            onChange={handleChangeMode}
                            checked={mode}
                          />
                        </div>
                      </div>}
                    </Col>
                  </Row>
                  {!mode ? (
                    <LiteNewSecretForm
                      defaultSecretTemplate={
                        profile ? profile.defaultSecretTemplate : {}
                      }
                    />
                  ) : (
                    <>
                      <div className="form-formik">
                        <div
                          aria-multiselectable={true}
                          className="card-collapse mb-3"
                          id="accordionTemplate"
                          role="tablist"
                        >
                          <Card className="card-plain">
                            <CardHeader role="tab">
                              <a
                                aria-expanded={openedCollapseTemplate}
                                href="#pabo"
                                data-parent="#accordionTemplate"
                                data-toggle="collapse"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if(!openedCollapseTemplate){
                                    setOpenedCollapseTemplate(!openedCollapseTemplate);
                                    setOpenedCollapseSecret(false);
                                    setOpenedCollapseSettings(false);
                                  }

                                }}
                              >
                                Template <span className="bold">({template.label})</span>
                                
                                <i className="tim-icons icon-minimal-down" />
                              </a>
                            </CardHeader>
                            <Collapse
                              role="tabpanel"
                              isOpen={openedCollapseTemplate}
                              className="collapse-settings"
                            >
                              <CardBody>
                                <Card className="card-plain card-subcategories">
                                  <CardBody>
                                    <Row>
                                      <Col md="4"></Col>
                                      <Col md="4">
                                        <div className="flex">
                                          {templates.length === 1 ? (
                                            <Label>
                                              You don't have a template yet.{" "}
                                              <b
                                                className={"cursor-p"}
                                                onClick={goToTemplates}
                                              >
                                                Create one
                                              </b>
                                            </Label>
                                          ) : (
                                            <Label>
                                              Templates
                                              <FontAwesomeIcon
                                                id="templateTooltip"
                                                icon={faInfoCircle}
                                                color="#0d6efd"
                                                width="40px"
                                              />
                                              <UncontrolledTooltip
                                                delay={0}
                                                target="templateTooltip"
                                                placement="right"
                                              >
                                                Templates allows you to save time by filling up
                                                descriptions and security settings automatically
                                              </UncontrolledTooltip>
                                            </Label>
                                          )}
                                          
                                        </div>
                                        <FormGroup>
                                          <Select
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            placeholder="Choose template"
                                            styles={colourStyles}
                                            name="select"
                                            value={template}
                                            onChange={(value) => setTemplate(value)}
                                            options={templates}
                                            isDisabled={templates.length <= 1}
                                          />
                                        </FormGroup>
                                      </Col>
                                      {false && (
                                        <Col className="d-none" md="6">
                                          <div className="box-label">
                                            {labelOptions.length <= 1 ? (
                                              <Label>
                                                You don't have a label yet.{" "}
                                                <b
                                                  className={"cursor-p"}
                                                  onClick={addNewLabel}
                                                >
                                                  Create one
                                                </b>
                                              </Label>
                                            ) : (
                                              <div>
                                                <Label className="">Label</Label>
                                                <FontAwesomeIcon
                                                  icon={faCog}
                                                  className="left5 cursor-p"
                                                  onClick={addNewLabel}
                                                />
                                              </div>
                                            )}
                                          </div>
                                          <CustomModalLabel
                                            isOpen={modalClassic}
                                            toggle={addNewLabel}
                                          />
                                          <Select
                                            className={`react-select ${
                                              multipleSelect[0]
                                                ? multipleSelect[0].color
                                                : "info"
                                            }`}
                                            classNamePrefix="react-select"
                                            placeholder="Choose label"
                                            name="multipleSelect"
                                            closeMenuOnSelect
                                            isMulti
                                            value={multipleSelect}
                                            onChange={(value) => setmultipleSelect(value)}
                                            options={labelOptions}
                                            isDisabled={labelOptions.length <= 1}
                                          />
                                        </Col>
                                      )}
                                    </Row>
                                  </CardBody>
                                </Card>
                              </CardBody>
                            </Collapse>
                          </Card>
                        </div>
                      </div>
                      <NewSecretForm
                        initialValues={initialValues}
                        setPrevValuesAux={setPrevValuesAux}
                        config={config}
                        setConfig={setConfig}
                        qty2Aux={qty2Aux}
                        addNewLabel={addNewLabel}
                        labelOptions={labelOptions}
                        modalClassic={modalClassic}
                        multipleSelect={multipleSelect}
                        setmultipleSelect={setmultipleSelect}
                        openedCollapseSecret={openedCollapseSecret}
                        setOpenedCollapseSecret={setOpenedCollapseSecret}
                        openedCollapseSettings={openedCollapseSettings}
                        setOpenedCollapseSettings={setOpenedCollapseSettings}
                        setOpenedCollapseTemplate={setOpenedCollapseTemplate}
                        customSecretSchemas={customSecretSchemas}
                        setCustomSecretSchemas={setCustomSecretSchemas}
                        customSecretSchemaValue={customSecretSchemaValue}
                        setCustomSecretSchemaValue={setCustomSecretSchemaValue}
                      />
                    </>
                  )}
                </div>
                :
                <div>
                  <Row>
                    <Col md="12">
                      <h3>Missing configuration</h3>
                      <h4 className="mt-5">
                        You don't have any encryption method enabled. Please, configure one of them 
                        {" "}<span onClick={goToSettingsEnvelope} className="u-link-cursor">here</span>!
                      </h4>
                    </Col>
                  </Row>
                </div>
                }
              </CardBody>}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NewSecret;
