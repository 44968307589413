import FrontendConfig from "./config.js";

const InstanceConfig = () => {
    const config = {
        "ymtechone": {
            envelopeLogo: require("../../envs/ymtechone/assets/img/ymtech.png"),
            qrLogo: "",
            envelope: true,
            emailSignUp: false,
            customSecretSchema: true,
        },
        "silentbreach": {
            envelopeLogo: require("../../envs/silentbreach/assets/img/logo.png"),
            qrLogo: "",
            envelope: true,
            emailSignUp: false,
            customSecretSchema: false,
        },
        "schur": {
            envelopeLogo: require("../../assets/img/sp.png"),
            qrLogo: require("../../assets/img/sp.png"),
            envelope: false,
            emailSignUp: false,
            customSecretSchema: false,
        },
        "natan": {
            envelopeLogo: require("../../assets/img/sp.png"),
            qrLogo: "",
            envelope: false,
            emailSignUp: false,
            customSecretSchema: true,
        },
        "centrevision": {
            envelopeLogo: require("../../assets/img/sp.png"),
            qrLogo: "",
            envelope: false,
            emailSignUp: false,
            customSecretSchema: false,
        },
        "ahp": {
            envelopeLogo: require("../../assets/img/sp.png"),
            qrLogo: "",
            envelope: false,
            emailSignUp: false,
            customSecretSchema: false,
        },
        "cfcsea": {
            envelopeLogo: require("../../envs/cfcsea/assets/img/cfcsea-logo.png"),
            qrLogo: "",
            envelope: true,
            emailSignUp: false,
        },
        "prod": {
            envelopeLogo: require("../../assets/img/sp.png"),
            qrLogo: require("../../assets/img/sp.png"),
            envelope: false,
            emailSignUp: true,
            customSecretSchema: false,
        },
        "tst": {
            envelopeLogo: require("../../assets/img/sp.png"),
            qrLogo: require("../../assets/img/sp.png"),
            envelope: true,
            emailSignUp: true,
            customSecretSchema: true,
        },
        "staging": {
            envelopeLogo: require("../../assets/img/sp.png"),
            qrLogo: require("../../assets/img/sp.png"),
            envelope: false,
            emailSignUp: true,
            customSecretSchema: false,
        },
        "kbi": {
            envelopeLogo: require("../../envs/kbi/assets/img/logo.png"),
            qrLogo: "",
            envelope: false,
            emailSignUp: false,
            customSecretSchema: false,
        }
    };

    return config[FrontendConfig.env];
};

export default InstanceConfig;